import React, { Component } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import "./book.sass";

class Book extends Component {
  render() {
    const { title, author, rating, review, cover, emote } = this.props.data;
    const emoti = emote || "smile";
    return (
      <div className="card book-card">
        <div className="card-content">
          <div className="columns is-mobile p-0">
            <div className="column is-narrow p-0 is-half-mobile book-image">
              {cover && <GatsbyImage image={cover} alt={title} />}
            </div>
            <div className="column book-review is-half-mobile">
              {/* <h4 className="has-text-weight-bold m-0">{title}</h4> */}
              {/* <h5 className="m-0">{author}</h5> */}
              {[...Array(rating)].map(_ => (
                <img class="emote" src={"/img/emotes/" + emoti + ".gif"}></img>
              ))}
              <p>{review}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Book;
